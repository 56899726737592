import { useEffect, useState } from "react"
import { FirebaseStorageapi } from "../apiintegration/FirebaseStorageapi"
import "./css/Itemestimatecard.css"

export const Itemestimatecard=(props)=>{

    const [url,setUrl]=useState(process.env.PUBLIC_URL + '\\logo192.png');

    useEffect(()=>{
        FirebaseStorageapi.getimgeurl(props.name).then((link)=>{
            setUrl(link)
        })
    },[])
    var quantity;
    switch(props.name){
        case "Air Conditioner":
            quantity="Nos";
            break;
        case "Fridge":
            quantity="Nos";
            break;
        default:
            quantity="Kg";
    }
    return(
        <div className="itemestimatecard">
            <img className="itemimage" src={url} alt={props.name}></img>
            <div className="material">{props.name}</div>
            <div className="quantity">Quantity : <input  type="number" name={"quantity."+props.name} value={props.quantity} onChange={props.onChange} min={0}></input> {quantity}</div>
            {/* <div className="priceperkg">Return : Rs {props.price} / kg</div> */}
        </div>
    )
}