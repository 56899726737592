import { useEffect, useState } from "react"
import { FirebaseStorageapi } from "../apiintegration/FirebaseStorageapi"
import "./css/Itemcard.css"

export const Itemcard=(props)=>{

    const [url,setUrl]=useState(process.env.PUBLIC_URL + '\\sw.svg');

    useEffect(()=>{
        FirebaseStorageapi.getimgeurl(props.name).then((link)=>{
            setUrl(link)
        })
    },[])

    var unit="";

    switch(props.name){
        case "LCD TV":
        case "Washing Machine":
        case "Fridge":
        case "Beer Bottle":
            unit="piece";
            break;
        default:
            unit="Kg";
    }
    

    return(
        <div className="itemcard">
            <img className="itemimage" src={url} alt={props.name}></img>

            <div className="itemcarddetail">
                <span><h4>{props.name}</h4></span>
                {<span>Rs {props.price} / {unit}</span>}
            </div>
        </div>
    )
}