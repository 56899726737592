import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Authenticationapi } from "../apiintegration/Authenticationapi"
import { GlobalContext } from "../App"
import "./css/Navbar.css"

export const Navbar=()=>{
    const [background,setBackground] = useState(false);
    const changeNavBg = (e) => {
        window.scrollY >= 100 ? setBackground(true) : setBackground(false);
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNavBg);
        return () => {
          window.removeEventListener('scroll', changeNavBg);
        }
      }, [])
    let context=useContext(GlobalContext)
    const navigate=useNavigate()

    const onsignout=async ()=>{
        try{
            let result = await Authenticationapi.signout()
            if(result === true){
                context.setStates({
                    countryCode:"",
                    userNumber:"",
                    addressline1:"",
                    addressline2:"",
                    addressline4:"",
                    city:""
                  })
                context.setStatus({
                    loginstatus:false,
                    locationconfirmation:false
                  })
                navigate("/")
            }
        }catch(err){
            alert("we are not able to sign you out", err)
        }
        
    }
    return(
        <nav className="kercollectornav"  style={{background:background?'rgba(49, 178, 49, 0.96)':''}}>
            <ul>
                <li>
                    <Link to="/" style={{color:"darkblue",display:"flex",alignItems:"center"}}>
                        <img src="/sw.svg" alt="Apna ScrapWala" height='66px' width='66px' style={{marginRight:"5px", transform:"scale(1.5)"}}/>
                        <b>Apna ScrapWala</b>
                    </Link>
                </li>
                <input type="checkbox" id="showinformationlink" className="showinformationlink"/>
                
                <li  className="informationlink">
                    <Link to="/prices">Items we Accept</Link>
                </li>
                
                
                <li className="informationlink">
                    <Link to="/about-us">About us</Link>
                </li>
                <li className="informationlink">
                    <Link to="/contact-us">Contact us</Link>
                </li>
                <li className="left user">
                    <a className="userdropdownname">{context.status.loginstatus && <>{context.states.countryCode} {context.states.userNumber} <i className="arrow down"></i></>}</a>
                    <div className="userdropdowm">
                        <ul>
                            <li onClick={()=>navigate("/pickup-order")}>
                                <span>Pickup Orders</span>
                            </li>
                            <li onClick={onsignout}>
                                <span>Log out</span>
                            </li>
                        </ul>
                    </div>
                </li>
                
                <li>
                    <Link to="/varify-mobile"><button>Schedule Pickup{!context.status.loginstatus && <> / Signin</>}</button></Link>
                </li>

               
                <label htmlFor="showinformationlink" className="showinformationlinklabel"> 
                    <div></div>
                    <div></div>
                    <div></div>
                </label>
                
            </ul>
        </nav>
    )
}