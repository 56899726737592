


export const TermsandCondition=()=>{
    return(
        <div className="page condition">
            <div>
                <h3>
                    Privacy policy :
                </h3>
                <p>
                    We are storing the data that collected from the user in the google cloud with access to the data to specific belonging user.
                </p>
                <p>
                    We will be collecting phone number and verifing the phone number using SMS to specific phone Number. By agreeing to our privacy policy means you are ready to share the your phone 
                    number and ready to verify the same.
                    We are not sharing data to any other organisation.
                </p>
                <p>
                    We will be collecting address details from user which are getting stored in the google cloud. shared address will be only accessible to specific user.
                </p>
                <p>
                    RAll the pickup order details will protected and will prevented from breaching the security.
                </p>
            </div>
            <div>
                <h3>
                    Following are the terms and conditions pickup request : 
                </h3>
                <p>
                    As we are providing service in New Mumbai, Pickup address should be in New Mumbai.
                </p>
                <p>
                    Pickup return should atleast exceed Rs 500
                </p>
                <p>
                    At time of the Pickup, We will access the material quantity and quality and accordingly we will adjust the return Amount.
                </p>
                <p>
                    Return amount will be paid at the Time of the pickup after accessing material for the pickup.
                </p>
            </div>

            
            
        </div>

                  
    )
}