import { doc, setDoc,collection,query, where,getDocs,updateDoc, getDoc,addDoc, serverTimestamp, snapshotEqual, orderBy, deleteDoc } from "firebase/firestore"; 
import { db } from "..";


export class Firestoreapi{
    static getuserdata=async(countryCode,userNumber)=>{
        let data;
        const datasnap=await getDoc(doc(db,"userData",countryCode+userNumber))
        data=datasnap.data() 
        return data
        
    }

    static postuserdata=async(countryCode,userNumber,data)=>{
       
        let result= await setDoc(doc(db,"userData",countryCode+userNumber),data)
        console.log(result)
        return true
    }


    static updateaddress=async(countryCode,userNumber,address)=>{
        let result =await updateDoc(doc(db,"userData",countryCode+userNumber),address)
        return result   
    }

    static getapplicationdata=async (documentid)=>{
        let result=await getDoc(doc(db,"applicationdata",documentid))
        return result.data()
    
    }

    static postpickuporder=async (data)=>{
        let result = await addDoc(collection(db,"pickuporder"),{...data,timestamp:serverTimestamp(),status:0})
        return result
    }

    static getpickupordersforuser=async (countryCode,userNumber)=>{
        let snapshot= await getDocs(query(collection(db,"pickuporder"),where("countryCode","==",countryCode),where("userNumber","==",userNumber),orderBy("timestamp","desc")))
        let response=[]
        snapshot.forEach((doc)=>{
            response.push({...doc.data(),id:doc.id})
        })
        return response
    }

    static cancelpickup=async(id)=>{
        let result=await updateDoc(doc(db,"pickuporder",id),{status:3})
        return result
    }

    static postquery=async(data)=>{
        let result=await addDoc(collection(db,"queries"),{...data,timestamp:serverTimestamp(),status:0})
        return result
    }
        
    
}