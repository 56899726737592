import React, { useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup,SVGOverlay } from 'react-leaflet'
import { SetView } from './Setview'
import "./css/testMap.css";
export const Leafletmap=(props)=>{


    /*React.useEffect(()=>{
            map.setOptions({
                zoom:props.zoom,
                center:props.center
            })

    },[props])*/

    const [map,setMap]=useState()
    React.useEffect(()=>{
        if(map){       
                props.setposition({lat:map.getCenter().lat,lng:map.getCenter().lng})
        }
    },[map])

    return(
        <div className="map">
            <div className="mappin">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                </svg>
            </div>
            
            <MapContainer center={[props.geoposition.lat, props.geoposition.lng]} zoom={15}
                            whenCreated={setMap}
                            >
                    <SetView center={[props.geoposition.lat, props.geoposition.lng]}></SetView>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {/*<Marker position={[props.geoposition.lat, props.geoposition.lng]}>
                        <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker>*/}
                    
            </MapContainer>
                    
        </div>
    )
}