import { Timestamp } from "firebase/firestore"
import { useFormik } from "formik"
import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Firestoreapi } from "../apiintegration/Firestoreapi"
import { GlobalContext } from "../App"
import { Itemestimatecard } from "../components/Itemestimatecard"
import "./css/Pickupestimate.css"
import {serverTimestamp} from "firebase/database"

export const Pickupestimate=()=>{

    const navigate=useNavigate()

    const [totalreturn,setTotalreturn]=useState(0)

    const context=useContext(GlobalContext)

    const [prices,setPrices]=useState({})

    const formik=useFormik({
        initialValues:{
            quantity:{ },
            scheduledate:""
        },
        validate:values=>{
            let errors={}
            if(values.scheduledate===""){
                errors.scheduledate="Required"
            }
            return errors
        },
        onSubmit:async ()=>{
            context.setStatus({...context.status,loadingstatus:true})
            try{
                let result = await Firestoreapi.postpickuporder({
                    quantity:formik.values.quantity,
                    prices:prices,
                    ...context.states,
                    totalreturn:totalreturn,
                    scheduledate:formik.values.scheduledate
                })
                context.setStatus({...context.status,loadingstatus:false})
                alert("successfully posted pickup")
                navigate("/pickup-order")
            }catch(err){
                context.setStatus({...context.status,loadingstatus:false})
                alert("pickup order not successfull"+err)
            }
            
            
        }
    })

    const [currentdate,setDate]=useState("")
    


    useEffect(()=>{
            Firestoreapi.getapplicationdata("pricesofmaterial").then((res)=>{
                setPrices(res)
                console.log(res)
                let quantity={}
                Object.keys(res).forEach((name)=>{
                    quantity[name]=0
                })
                formik.setFieldValue("quantity",quantity)
            }).catch((err)=>{
                alert(err)
            })

    },[])
    useEffect(()=>{
        let sum=0
        Object.keys(formik.values.quantity).forEach((quantityname)=>{
            sum=sum+formik.values.quantity[quantityname]*prices[quantityname]
        })

        setTotalreturn(sum)
    },[formik.values.quantity])

    
    if(context.status.locationconfirmation===false){
        return(<div className="outerwindow">
                    <div className="outerbox">
                        <h1>
                            Please fill the address details
                        </h1>
                        <Link to="/location"><button>Go to address page</button></Link>
                    </div>
                </div>)
    }else{
        return(<div className="pickupwindow">

                <div className="pickupestimate">
                    <h2>Material quantity and price estimate</h2><br/>
                    <div className="table">
                        
                        {/*<div className="heading">
                            <span className="material">Material type</span>
                            <span className="quantity" >quantity in Kg</span>
                            <span className="priceperkg">Return Rupees/Kg</span>
                        </div>*/}
                        {Object.keys(prices).sort().map((name)=>{
                            return(
                                <Itemestimatecard name={name} quantity={formik.values.quantity[name]} price={prices[name]} onChange={formik.handleChange}/>
                            )
                        })}
                
                    </div>
                
                    <hr/>
                

                    <div className="schedulemodule">
                        <div className="estimatedprice"><b>Total Estimated price</b> <br/> Rs {totalreturn}</div>
                        <div>
                            <label><b>Schedule date</b></label><br/>
                            <input type="date" 
                            className="scheduledate" 
                            value={formik.values.scheduledate} 
                            name="scheduledate"
                            min={currentdate}
                            onChange={formik.handleChange}></input>
                            {formik.touched.scheduledate && <div>{formik.errors.scheduledate}</div>}
                        </div>
                        <button type="submit" onClick={formik.handleSubmit}>Book a Pickup</button>
                        
                    </div>
                    
                </div>

            </div>
           
        )
    }
    
}