import { useContext } from "react"
import { Link, Outlet } from "react-router-dom"
import { GlobalContext } from "../App"



export const Pageprotection=(props)=>{
    const globalcontext=useContext(GlobalContext)
   

    if(globalcontext.status.loginstatus){
        return(
            <Outlet/>
            
        )
    }else{
        return(<div className="outerwindow">
                <div className="outerbox">
                    <h2>
                        Please verify number before filling the location details.
                    </h2>
                    <Link to="/varify-mobile"><button>navigate to verify number</button></Link>
                </div>
            </div>
            )
    }

}