import { storage } from "..";
import { ref, getDownloadURL } from "firebase/storage";



export class FirebaseStorageapi{
    static getimgeurl=async (imagename)=>{
        var link=await getDownloadURL(ref(storage, `images/${imagename}.jpg`))
            .then((url) => {
                return url
            })
        return link;
    }
}