import { useEffect, useState, useContext } from "react"
import { Firestoreapi } from "../apiintegration/Firestoreapi"
import { Itemcard } from "../components/Itemcard"
import "./css/Items.css"
import { GlobalContext } from "../App";


export const Items=()=>{


    const [prices,setPrices]=useState({})
    let context=useContext(GlobalContext)
    useEffect(()=>{
           context.setStatus({...context.status,loadingstatus:true})
            Firestoreapi.getapplicationdata("pricesofmaterial").then((res)=>{
                setPrices(res)
                context.setStatus({...context.status,loadingstatus:false})
            }).catch((err)=>{
                alert(err)
            })

    },[])

        return(
            <div className="itemsswindow">  
            <h2>Items We Accept</h2>
                <div className="items">
                    
                    {Object.keys(prices).sort().map((name)=>{
                            return(
                                <Itemcard name={name} price={prices[name]}/>
                            )
                        })}
                </div>
            </div>
        )
    }
    
