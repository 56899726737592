import { useFormik } from "formik"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Firestoreapi } from "../apiintegration/Firestoreapi"
import { GlobalContext } from "../App"
import "./css/Location.css";
import React from "react"
//import { Map } from "../components/Map"
import { Leafletmap } from "../components/Leafletmap"

/*const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <div>loadinmg</div>;
      case Status.FAILURE:
        return <div>error</div>;
    }
};*/


export const Location=()=>{

    let context=useContext(GlobalContext)
    const  navigate=useNavigate()

    const [geoposition,setpostion]=React.useState({ lat: 0, lng: 0 })

    useEffect(()=>{
        if(context.status.loginstatus){
            Firestoreapi.getuserdata(context.states.countryCode,context.states.userNumber)
                .then((userdata)=>{
                    if(userdata){
                        formik.setValues({
                            addressline1:userdata.addressline1,
                            addressline2:userdata.addressline2,
                            addressline4:userdata.addressline4,
                            addressline5:userdata.addressline5,
                            city:userdata.city,
                            country:userdata.country
                        })
                        if(userdata.geoposition){
                            setpostion(userdata.geoposition)
                        }else{
                            if ("geolocation" in navigator){
                                navigator.geolocation.getCurrentPosition(function(position) {
                                    setpostion({lat:position.coords.latitude,lng:position.coords.longitude})
                                });
                            }

                        }
                        
                    }else{
                        if ("geolocation" in navigator){
                            navigator.geolocation.getCurrentPosition(function(position) {
                                setpostion({lat:position.coords.latitude,lng:position.coords.longitude})
                            });
                        }
                    }
                }).catch((e)=>{
                    console.log(e)
                    alert("there error retriving the userdata please try again afterwards",e)
                    
                })

        }
        
        
                
    },[])

    
    
    const formik=useFormik({
        initialValues: {
            addressline1:"",
            addressline2:"",
            addressline4:"",
            addressline5:"",
            city:"New Mumbai",
            country:"India"
        },
        validate:(values)=>{
            let errors={}
            if(values.addressline1==="" || values.addressline1.length>40){
                errors.addressline1="Required and maximum nu. of characters availble is 40"
            }
            if(values.addressline2==="" || values.addressline2.length>40){
                errors.addressline2="Required and maximum nu. of characters availble is 40"
            }
            // if(values.addressline3==="" || values.addressline3.length>40){
            //     errors.addressline3="Required and maximum nu. of characters availble is 40"
            // }
            if(values.addressline4.length !== 6 || !values.addressline4.match(/^[0-9]*$/)){
                errors.addressline4="pincode is Required and pincode should have 6 digits"
            }

            return errors
        },
        onSubmit: async (values) => {
            context.setStatus({...context.status,loadingstatus:true})
            let result
            try{
                result=await Firestoreapi.postuserdata(context.states.countryCode,context.states.userNumber,{
                    countryCode:context.states.countryCode,
                    userNumber:context.states.userNumber,
                    ...values,
                    geoposition
                })
                console.log(result)

                if(result){
                    context.setStates({
                        ...context.states,
                        addressline1:values.addressline1,
                        addressline2:values.addressline2,
                        addressline4:values.addressline4,
                        addressline5:values.addressline5,
                        city:values.city,
                        country:values.country,
                        geoposition
                    })
                    context.setStatus({...context.status,locationconfirmation:true,loadingstatus:false})
                    navigate("/pickup-estimate")
                    
                    
                }
            }catch(err){
                context.setStatus({...context.status,loadingstatus:false})
                alert("location posting not successfull" + err)
                result=false
            }
           
            
            

        }
    })

    return(
        <div className="locationwindow">
            {/*<Wrapper apiKey={"AIzaSyCb8kr4G-vc0AvU_hemYX_oAPAIpuwLEk0"} render={render}>
                    <Map center={geoposition} zoom={16} setposition={setpostion}>
                    </Map>
            </Wrapper>*/}

            
            <Leafletmap geoposition={geoposition} setposition={setpostion}/>
            
            
            <div className="location">
                
                <form  onSubmit={formik.handleSubmit}>
                    <h2>
                        Please enter your address so we can collect Pickup from your home
                    </h2>
                    <div>
                        <label>Address Line 1</label><span className="compulsory">*</span><br/>
                        <input 
                        placeholder="House No./Building number/Appartment no."
                        name="addressline1"
                        value={formik.values.addressline1}
                        onChange={formik.handleChange}
                        />{formik.errors.addressline1}

                    </div>

                    <div>
                        <lable>Address Line 2</lable><span  className="compulsory">*</span><br/>
                        <input 
                        placeholder="Street/road/lane"
                        name="addressline2"
                        value={formik.values.addressline2}
                        onChange={formik.handleChange}
                        />{formik.errors.addressline2}
                    </div><br/>

                    {/* <div>
                        <lable>Area/sector</lable><span  className="compulsory">*</span><br/>
                        <input 
                        placeholder="Area/sector"
                        name="addressline3"
                        value={formik.values.addressline3}
                        onChange={formik.handleChange}
                        />{formik.errors.addressline3}
                    </div> */}

                    <div>
                        <lable>City</lable><br/>
                        <input className ="disabled" placeholder="City" value={formik.values.city} disabled="disabled"/><br/>
                    </div>
           

                    <div>
                        <lable>Pincode</lable><span  className="compulsory">*</span><br/>
                        <input 
                        placeholder="Pincode"
                        name="addressline4"
                        value={formik.values.addressline4}
                        onChange={formik.handleChange}
                        />{formik.errors.addressline4}
                    </div>
                    
                    <div>
                            <lable>Landmark</lable><br/>
                            <input 
                            placeholder="Landmark"
                            name="addressline5"
                            value={formik.values.addressline5}
                            onChange={formik.handleChange}
                            />
                    </div><br/>

                
                    <lable>country</lable><br/>
                    <input className ="disabled" placeholder="country" value={formik.values.country} disabled="disabled"/><br/>
                    <button type="submit">Continue</button>
                </form>

                
            </div>
            
            
            
        </div>
        
    )
}
