
import './App.css';
import { BrowserRouter as Router} from "react-router-dom";
import { Pagerouter } from './routers/Pagerouter';
import { Navbar } from './components/Navbar';
import React, { useState } from 'react';
import { Footer } from './components/Footer';

export const GlobalContext = React.createContext();



function App() {

  const [states,setStates]=useState({
    countryCode:"",
    userNumber:"",
    addressline1:"",
    addressline2:"",
    addressline4:"",
    city:"",
    geoposition:{}
  })

  const [status,setStatus]=useState({
    loginstatus:false,
    locationconfirmation:false,
    loadingstatus:false
  })
  
  return (
    <div className="App">
      <GlobalContext.Provider value={{states:states,setStates:setStates,status:status,setStatus:setStatus}}>
        {status.loadingstatus && <div className='loading'>
          <div></div>
        </div>}
        <Router>
          <Navbar/>
          <Pagerouter/>
          <Footer/>
        </Router>
      </GlobalContext.Provider>
      
    </div>
  );
}

export default App;
