
import { RecaptchaVerifier,signInWithPhoneNumber, signOut } from 'firebase/auth';
import { auth } from '..';

export const window1 = {
    recaptchaVerifier: undefined
  };


export class Authenticationapi{
    static sendverificationcode=async (phoneNumber)=>{

        window1.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
            }, auth);
        
        const appVerifier = window1.recaptchaVerifier;
        let result=await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            
        return result
    }

    static verifynumber=async (confirmationResult,code)=>{
        let reuslt = await confirmationResult.confirm(code)
            // User signed in successfully.
        const user = reuslt.user;
        return user
            // ...
    }

    static signout=async()=>{
        let result=await signOut(auth)
        return true
        
    }
}