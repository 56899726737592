import { Firestoreapi } from "../apiintegration/Firestoreapi"
import "./css/Pickupcard.css"


const appdata=[
    "pickup request submitted",
    "Pickup scheduled",
    "completed",
    "cancelled"
]

export const Pickupcard=(props)=>{


    const oncancel=async()=>{
        try{
            await Firestoreapi.cancelpickup(props.data.id)
            alert(props.data.id+" pickup has been cancelled")
            props.getpickupordersforuser()
        }catch(err){
            alert("currently we are not able to cancel the pickup",err)
        }
        
    }

    
    return(
        <div className="pickupcard">
            
            <div className="details0" style={{backgroundColor:props.data.status===3?"rgb(209, 113, 88)":"rgb(35, 222, 69)"}}>
                <div><h5>Order ID</h5>{props.data.id}</div>
                <div>
                <h5>Request Date {"&"} Time </h5> 
                {props.data.timestamp.toDate().getDate()}-{props.data.timestamp.toDate().getMonth()+1}-{props.data.timestamp.toDate().getFullYear()} {props.data.timestamp.toDate().getHours()}:{props.data.timestamp.toDate().getMinutes()} 
                </div>
                
            </div>
            <div className="details1">
                <div><h5>estimated return</h5> Rs {props.data.totalreturn}</div>
                <div><h5>Status</h5>{appdata[props.data.status]}</div>
                
                <div>
                    <h5>Pickup Schedule date</h5>
                    {props.data.scheduledate}
                </div>
                <div>
                    {props.data.status!==3 && <button className="cancelbutton" onClick={oncancel}>Cancel pickup</button>}
                </div>
            </div>
            
            
            <div className="details2">
                <div>
                        <h5>Pickup address</h5>
                        <div>{props.data.addressline1}, {props.data.addressline2}</div>
                        <div> {props.data.addressline4}</div>
                        <div>Landmark : {props.data.addressline5}</div>
                        <div>{props.data.city}, {props.data.country}</div>
                </div>
                
                
                

                <div>
                    <h5>Materials</h5>
                    {Object.keys(props.data.quantity).map((item)=>{
                        if(props.data.quantity[item]!==0){
                            return(
                            <div>{item}</div>
                            )
                        }
                        
                    })}
                </div>
            </div>
            
        </div>
    )
}