import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Firestoreapi } from "../apiintegration/Firestoreapi"
import { GlobalContext } from "../App"
import { Pickupcard } from "../components/Pickupcard"
import "./css/Pickuporder.css"

export const Pickuporder=()=>{

    const context=useContext(GlobalContext)

    const [pickuporders,setPickuporders]=useState([])

    const getpickupordersforuser=()=>{
        Firestoreapi.getpickupordersforuser(context.states.countryCode,context.states.userNumber).then((res)=>{
            console.log(res)
            setPickuporders(res)
        })
    }

    useEffect(()=>{
        if(context.status.loginstatus===true){
            context.setStatus({...context.status,loadingstatus:true})
                Firestoreapi.getpickupordersforuser(context.states.countryCode,context.states.userNumber).then((res)=>{
                    console.log(res)
                    setPickuporders(res)
                    context.setStatus({...context.status,loadingstatus:false})
                }).catch((err)=>{
                    console.log(err)
                    context.setStatus({...context.status,loadingstatus:false})
                    alert("error",err)
                })
        } 
    },[])

    if(context.status.loginstatus===false){
        return(
            <div className="outerwindow">
                <div className="outerbox">
                        <h2>
                            Kindly verify the mobile number for checking the pickup orders
                        </h2>
                        <Link to="/varify-mobile"><button>Verify number</button></Link>
                    </div>
            </div>
        )
       
    }else{
        return(
            <div className="pickuporder">
                <h3>Pick up orders</h3>

                {pickuporders.length===0 && <>There are no pickups requests submitted</>}
                <div className="pickuparray">
                {   pickuporders.length!==0  &&
                    pickuporders.map((pickuporder)=>{
                        return(
                            <Pickupcard data={pickuporder} getpickupordersforuser={getpickupordersforuser}></Pickupcard>
                        )
                    })
                }
                </div>
                
            </div>
        )
    }
    
}