import { useContext, useState } from "react"
import { Link,useNavigate } from "react-router-dom"
import "./css/Varifymobile.css"
import { useFormik } from 'formik';
import { GlobalContext } from "../App";

import { Authenticationapi } from "../apiintegration/Authenticationapi";

let varifierobject=undefined

export const Varifymobile=()=>{

    const [sendcodestatus,setSendcodestatus]=useState(false)
    const [countryCode,setContrycode]=useState("+91")
    const navigate=useNavigate()
    let context=useContext(GlobalContext)
    const formik = useFormik({
        initialValues: {
            mobileNumber: '',
            varificationCode: '',
        },
        validate: values => {
          const errors = {};
          if (!values.mobileNumber) {
            errors.mobileNumber = 'Required';
          } else if (values.mobileNumber.length !== 10) {
            errors.mobileNumber = 'Must be 10 numbers';
          }
        
          if (!values.varificationCode && sendcodestatus) {
            errors.varificationCode = 'Required';
          } else if (values.varificationCode.length !== 6 && sendcodestatus) {
            errors.varificationCode = 'Please enter valid varification code';
          }
        
          return errors;
        },
        onSubmit: async (values) => {
            if(sendcodestatus){
              try{
                context.setStatus({...context.status,loadingstatus:true})
                await Authenticationapi.verifynumber(varifierobject,values.varificationCode)
                context.setStates({...context.states,countryCode:countryCode,userNumber:values.mobileNumber})
                context.setStatus({...context.status,loginstatus:true,loadingstatus:false})
                navigate("/location")
                
              }catch(err){
                context.setStatus({...context.status,loadingstatus:false})
                alert("we were not able to verify the number" , err)
              }
            

            }else{
              try{
                context.setStatus({...context.status,loadingstatus:true})
                varifierobject=await Authenticationapi.sendverificationcode(countryCode+values.mobileNumber)
                context.setStatus({...context.status,loadingstatus:false})
                setSendcodestatus(true)
                
              }catch(err){
                context.setStatus({...context.status,loadingstatus:false})
                console.log(err)
                alert("OTP not sent", err)
              }
            }
        },
      });

      const onChangecountrycode=(e)=>{
        setContrycode(e.target.value)
      }

      if(context.status.loginstatus===false){
        return(
          <div className="varifywindow">
            <div className="varify">
              
              <div className="varifymobile">
              <h3 className="varifymobilemessage">Please verify your mobile number</h3>
              <form   onSubmit={formik.handleSubmit}>
                  
                  <div>
                      <select value={countryCode} className="countryselector" onChange={onChangecountrycode} disabled={sendcodestatus?"disabled":""}>
                        <option value={'+91'}>India(+91)</option>
                      </select>
                      <input 
                      name="mobileNumber"
                      className="mobilenumber"
                      value={formik.values.mobileNumber}
                      onChange={formik.handleChange}
                      placeholder="Mobile number"
                      disabled={sendcodestatus?"disabled":""}
                      ></input><br/>{formik.errors.mobileNumber}
                  </div>
  
                  <button type="submit" id="sign-in-button" disabled={sendcodestatus?"disabled":""}>Send verification code</button>
              </form>
              <form  onSubmit={formik.handleSubmit} >
                {sendcodestatus && <div><input 
                    name="varificationCode"
                    className="varificationcode"
                    value={formik.values.varificationCode}
                    onChange={formik.handleChange}
                    placeholder="verification code"
                    ></input><div>{formik.errors.varificationCode}</div>
                    <button type="submit">verify</button></div>}
              </form>
              </div>
          </div>
          </div>
          
          
      )
      }else{
        return(
          <div className="varifywindow">
            <div className="varify">
              <h3 className="varifymobilemessage">We need your mobile number in order to communicate with you</h3>
                <div className="varifymobile">
                  <h5>You have already verified and logged in with number</h5>
                  <div><span>{context.states.countryCode} {context.states.userNumber}</span></div>
                  <Link to="/location"><button> continue</button></Link>
                </div>
            </div>
              
          </div>
        )
        
      }
    
}