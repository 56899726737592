import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth} from 'firebase/auth';
import { getStorage } from "firebase/storage";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCb8kr4G-vc0AvU_hemYX_oAPAIpuwLEk0",
  authDomain: "scrap-cef06.firebaseapp.com",
  projectId: "scrap-cef06",
  storageBucket: "scrap-cef06.appspot.com",
  messagingSenderId: "647759052820",
  appId: "1:647759052820:web:88f77668b6adce723aa701",
  measurementId: "G-1GK6Y8G690"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeLPC8gAAAAAM_2Xm-D8eDdwWK3Ud_7Fk_HBLxm'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export const db=getFirestore()
export var auth = getAuth();
export const storage = getStorage(app);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
