import { Banner } from "../components/Banner";
import "./css/Home.css"

export const Home=()=>{
    return(
        <div className="home">

              
                    <Banner/>

                    <div className="pages">
                        <div className="page process">
                            <span>Our process is simple. Just book the pickup. 
                                We will measure the weight at the time of pickup and accordingly 
                                we will provide returns for your scrap</span>
                            <div className="processflowchart">
                                <div className="processbox">
                                    <div className="scheduleimg">
                                        
                                    </div>
                                    
                                    <div className="description">
                                        <h4><div>step 1</div>Schdule  pickup online</h4>
                                        <p>schedule the Pickup from our website</p>
                                        
                                    </div>
                                    <div className="shadow"></div>
                                    
                                
                                </div>
                                <div className="processbox">
                                    <div className="measurmentimg">
                                    
                                    </div>
                                    <div className="description">
                                        <h4><div>step 2</div>Weight measurment by personnel</h4>
                                        <p>At time of Pickup we will check the weight of material by certified instruments.</p>
                                        
                                    </div>
                                    <div className="shadow"></div>
                                
                                </div>
                                
                                <div className="processbox">
                                    
                                    <div className="returnsimg">
                                    
                                    </div>
                                    
                                    <div className="description">
                                    
                                        <h4><div>step 3</div>Money Returns</h4>
                                        <p>
                                        As per accessment we will Provide the returns at time of Pickup.
                                        </p>
                                    </div>
                                    <div className="shadow"></div>
                                    
                                </div>

                            </div>
                            

                        </div>

                        <div className="page concernpage" >
                            <div className="recycleimg">
                                </div>
                            <div className="concern">
                                <h3>
                                    Our Belief
                                </h3>
                                <p>
                                Now a days buying items online is very easy but at the same time desposing waste that is created by these items is very hard. 
                                We are working towards solution for this problem.
                                </p>
                                <p style={{color:"green"}}> We can see from the chart on left side, by recycling we can save natural resources and make our environment better for ourself</p>
                                <h3>Our Mission </h3>
                                <p>
                                Our mission is to create a world where some innovative things can be created from waste by recycling it.<br/>
                                To reduce the amount of waste by promoting individual and corporate responsibility. 
                                <br/>
                                To recover waste for its highest and best use while balancing rates and services. <br/>
                                To transform our business from burying waste to utilizing waste as a resourc by recycling it.
                                
                                </p>
                            </div>
                        </div>
                    </div> 
        </div>
    )
}