import { Routes, Route, Link } from "react-router-dom"
import { Varifymobile } from "../pages/Varifymobile"
import { Home } from "../pages/Home"
import { Location } from "../pages/Location"
import { Pickupestimate } from "../pages/Pickupestimate"
import { Pickuporder } from "../pages/Pickuporder"
import { Items } from "../pages/Items"
import { Contactus } from "../pages/Contactus"
import { AboutUs } from "../pages/AboutUs"
import { Pageprotection } from "../HOC/Pageprotection"
import { Pageprotection2 } from "../HOC/Pageprotection2"
import { TermsandCondition } from "../pages/TermsandCondition"

export const Pagerouter=()=>{
    return(

        <>
        <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/about-us" element={<AboutUs/>}/>
               
               <Route path="/prices" element={<Items></Items>}/>
               <Route path="/contact-us" element={<Contactus/>}/>
               <Route path="/varify-mobile" element={<Varifymobile />} />
               <Route path="/terms-and-condition" element={<TermsandCondition/>} />
               
               <Route element={<Pageprotection></Pageprotection>}>
                    <Route path="/pickup-estimate" element={<Pickupestimate/>}/>
                    <Route path="/pickup-order" element={<Pickuporder></Pickuporder>}/>
                </Route>
               
              
               <Route path="/location" element={<Pageprotection2 component={Location}/>}/>


               <Route path="*" 
                    element={
                    <div style={{height:"100vh",
                                display:"flex",
                                flexDirection:"column",
                                justifyContent:"center"}}>
                        <h1>page not found</h1>
                        <Link to="/"><button>Go to Home</button></Link>
                    </div>
                    }
                />
            </Routes>
            
            </>
            
    )
}