import { useState } from "react"
import "./css/Banner.css"

export const Banner=()=>{

    const [staticstics,setStaticstics]=useState({
        completedpickups:10,
        materialrecycled:30,
        noofcustomers:7

    })
    return(
        <div className="banner">
                <div className="bannercontent">
                    <h2 className="bannerheading">
                    Srcap Pickup Service at your doorstep</h2><br/>
                    <h1 className="subtext">We’ll provide you the best value for all the Srcap with an easy pickup.
                        <br/>Let’s revolutionize scrap recycling together</h1>
                    
                    <div className="staticstics">
                        <div className="data">
                            <h5>
                                Completed pickups
                            </h5>
                            <div>
                                {staticstics.completedpickups}
                            </div>
                        </div>
                        <div className="data">
                            <h5>
                                material recycled
                            </h5>
                            <div>
                                {staticstics.materialrecycled} kg
                            </div>
                        </div>
                        <div className="data">
                            <h5>
                                Number of customers
                            </h5>
                            <div>
                                {staticstics.noofcustomers}
                            </div>
                        </div>
                    
                    </div>
                </div>
                
                <div className="image">
                    
                </div>
            
           
        </div>
    )
   
}